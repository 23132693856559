<template>
    <div class="option_loginPassword">
        <h3>Identifiant et mot de passe</h3>
        <vs-divider/>
        <vx-card>
            <span class="red">Le login doit contenir plus de 5 caractéres pour pouvoir être changé</span><br/><br/>
            <div class="vx-col w-full">
                <vs-input class="w-full" label="Nouveau login" v-model="login" />
            </div>
            <br/>
            <vs-row>
                <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
                    <div></div>
                    <vs-button color="success" type="filled" v-on:click="updateLogin"> Modifier login</vs-button>
                </vs-col>
            </vs-row>
        </vx-card>
        <br/>
        <vx-card>
            <span class="red">Le mot de passe doit contenir plus de 8 caractères ainsi que des nombres et un caractère spécial, ex : !, @, : ; $.<br/>
                        Exemple de mot de passe: "nomprenom@1990"</span><br/><br/>
            <div class="vx-col w-full">
                <vs-input class="w-full" label="Nouveau password" v-model="password" type="password" />
            </div>
            <div class="vx-col w-full">
                <vs-input class="w-full" label="Retaper le password" v-model="password2" type="password" />
            </div>
            <br/>
            <vs-row>
                <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
                    <div></div>
                    <vs-button color="success" type="filled" v-on:click="updatePassword"> Modifier password</vs-button>
                </vs-col>
            </vs-row>
        </vx-card>
    </div>
</template>



<style lang="scss">
.option_loginPassword{
    .red{
        color:red;
    }
}
</style>

<script>

import autoRefresh from '@/assets/utils/autoRefresh'

export default {
    data(){
        return{
            login:'',
            password:'',
            password2:'',
        }
	},
	mounted:function()
	{
        //autoRefresh
		autoRefresh.SetCallback(()=>{
			//refresh todo
        })
        autoRefresh.refresh()
    },
    watch:{
        $route (){
            
        }
    },
    methods:{
		updateLogin(){
            if(this.login.length>5)
            this.$srvApi.Req('patch', '/user',{
                login: this.login
            })
            .then((rep)=>{
                if(rep.status=='success')
                    alert('Login modifié !')
                else
                    alert('Impossible de modifier le login')
            })
        },
        updatePassword(){
            if(this.password != this.password2)
                return alert('Password différent !')

            if(this.password.length>=8)
            this.$srvApi.Req('patch', '/user',{
                password: this.password
            })
            .then((rep)=>{
                if(rep.status=='success')
                    alert('password modifié !')
                else
                    alert('Impossible de modifier le password')
            })
        },
    }
};

</script>